import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ContentBlock from "../../components/shared/sub/contentBlock"

import PrivacyStyle from "./privacyContentStyle"

interface Props {
  policyContent: object;
  policyName: string;
}

const PolicyContent = (props: Props) => {

  const { policyContent, policyName } = props

  return (
    <PrivacyStyle>
      <div className="container content">
        <div className="main">
          <h1>{policyName}</h1>
          <ContentBlock blocks={policyContent._rawContent} />
        </div>
      </div>
    </PrivacyStyle>
  )
}

export default PolicyContent
