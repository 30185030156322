import styled from "styled-components"

export default styled.section`
  background-color: transparent;

  .container.content {
    display: grid;
    grid-template-columns: 65% 30%;
    grid-gap: 5%;
    padding: 8rem 0;
    max-width: 1200px;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;
    }

    ol {
      li {
        list-style-type: initial;
      }
    }
    .main {
      h1 {
        margin-bottom: 50px;
        font-size: 32px;
      }
      * {
        color: var(--red);
      }

      img {
        width: 100%;
        margin: 2rem 0;
      }

      h4 {
        margin-top: 30px;
        margin-bottom: 5px;
        font-family: "bold";
      }

      p {
        margin-bottom: 2rem;
      }

      a {
        font-family: "bold";
        text-decoration: underline;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }

        transition: opacity 0.2s;
      }

      ul {
        li {
          position: relative;
          padding-left: 1.8rem;
          margin-bottom: 1rem;
          color: var(--red);
          > ul {
            margin: 10px;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0.7rem;
            display: block;
            width: 0.8rem;
            height: 0.8rem;
            background-color: var(--red);
            border-radius: 1rem;
            /* opacity: 0.8; */
          }
        }
      }
    }

    .side-bar {
      h3 {
        color: var(--red);
        font-size: 2rem;
        margin-bottom: 2rem;
      }

      & > div {
        margin-bottom: 5rem;
      }
    }
  }
`
