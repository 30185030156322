import React from "react"
import {useStaticQuery, graphql} from 'gatsby' 

import PolicySEO from "../components/shared/policySEO"
import PolicyContent from "../components/privacy/policyContent"

const PrivacyStatement = () => {

  const { sanityPrivacyStatement} = useStaticQuery(graphql`
  {
    sanityPrivacyStatement {
      policyContent {
        _rawContent
      }
          seo {
          pageTitle
          pageDescription
          pageKeyWords
          ogImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
    }
  }
  `)


  return (
    <div className="subpage-container">
      <PolicySEO seoInformation={sanityPrivacyStatement.seo} />
      <PolicyContent policyContent={sanityPrivacyStatement.policyContent} policyName="Privacy Statement"/>
    </div>
  )
}

export default PrivacyStatement
