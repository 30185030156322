import React from "react"
import SEO, { seoDataFormat } from "./seo"

interface Props {
  seoInformation: object;
}

const PolicySEO = (props: Props) => {

  const { seoInformation} = props

  const seoData = seoDataFormat(seoInformation)

  return <SEO {...seoData} />
}

export default PolicySEO
